import { Box, Button, Grid, Typography, alpha, useTheme } from "@mui/material";
import React from "react";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";

const EventBanner = ({ title, subtitle, image }) => {
    const theme = useTheme();
    return (
        <Grid
            container
            sx={{
                overflow: "hidden",
                backgroundSize: "cover",
                backgroundColor: "whitesmoke",
                p: 6,
            }}
            spacing={2}
        >
            <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item
                xs={12}
                spacing={2}
                md={6}
            >
                <Box sx={{ p: 5 }}>
                    <Typography textAlign={"left"} variant="h3">
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    </Typography>
                    <Typography sx={{ my: 2 }} textAlign={"left"}>
                        Dolorum dolores quis soluta modi porro suscipit! Excepturi laudantium molestiae, quibusdam officiis praesentium quidem. Similique, accusantium fugiat. Rerum, cupiditate optio? Aliquid, quas!
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export default EventBanner;
