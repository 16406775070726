import { FaFacebook, FaInstagram, FaSnapchat, FaTiktok } from "react-icons/fa";
import { FaXTwitter, FaThreads } from "react-icons/fa6";


export const footerData = {
  entreprise: {
    title: "LV SPA",
    subtitles: [
      {
        title: "Tarifs",
        path: "/tarifs",
      },
      {
        title: "A propos de nous",
        path: "/a-propos",
      },

      {
        title: "Mentions Légales",
        path: "/mentions",
      },
    ],
  },

  prestations: {
    title: "Nos soins",
    subtitles: [
      {
        id: 1,
        title: "Mannicure",
        image: "/travaux.jpg",
        details:
          "Vos projets deconstruction méritent un partenaire fiable et efficace. Petits ou grands travaux de construction de bâtiments, de rénovation, de génie Civil, des routes, ou d’ouvrages d’art. Nous ne faisons aucun compromis sur la qualité. Consol360 est spécialisée dans la construction, l'analyse de défauts, la recherche de pannes,...",
      },
      {
        id: 2,
        title: "Epilation laser",
        image: "/travaux.jpg",
        details:
          "Vos projets deconstruction méritent un partenaire fiable et efficace. Petits ou grands travaux de construction de bâtiments, de rénovation, de génie Civil, des routes, ou d’ouvrages d’art. Nous ne faisons aucun compromis sur la qualité. Consol360 est spécialisée dans la construction, l'analyse de défauts, la recherche de pannes,...",
      },
      {
        id: 3,
        title: "Soins de visage",
        image: "/nettoyage.jpg",
        details:
          "Consol360 nettoie, entretien, désinsectise, dératise et s'occupe des immondices de vos bureaux, maison, grande surface,...",
      },
    ],
  },
  communnity: {
    title: "Contact",
    socials: [
      {
        name: "Facebook",
        link: "https://www.facebook.com/VoiceOfCongo/",
        icon: <FaFacebook style={{ color: 'white' }} />,
      },
      {
        name: "Instagram",
        link: "https://www.instagram.com/voiceofcongo",
        icon: <FaInstagram style={{ color: 'white' }} />,
      },
      {
        name: "Tiktok",
        link: "https://www.tiktok.com/@voiceofcongo",
        icon: <FaTiktok style={{ color: 'white' }} />,
      },
      {
        name: "Twitter",
        link: "https://x.com/VoiceOfCongo",
        icon: <FaXTwitter style={{ color: 'white' }} />,
      },
      {
        name: "SnapChat",
        link: "https://snapchat.com/",
        icon: <FaSnapchat style={{ color: 'white' }} />,
      },
      {
        name: "Threads",
        link: "https://www.threads.net/@voiceofcongo",
        icon: <FaThreads style={{ color: 'white' }} />,
      },
    ],
  },
};

export const informations = [
  {
    title: "Actualités",
    href: "/actu",
  },
  {
    title: "Buzz",
    href: "/buzz",
  },
  {
    title: "Culture",
    href: "/culture",
  },
  {
    title: "Politque",
    href: "/politque",
  },
  {
    title: "Sport",
    href: "/sport",
  },
];

export const services = [
  {
    name: "Diffusion sur les réseaux sociaux",
  },
  {
    name: "Couverture événementielle",
  },
  {
    name: "Rédaction d'article",
  },
  {
    name: "Webinaire",
  },
  {
    name: "Interview & Réportage",
  },
  {
    name: "Documentaire",
  },
]


