import React from 'react'
import CongoPreneurBanner from '../../components/congopreneur/Banner'
import { Helmet } from 'react-helmet'

const CongoPreneur = () => {
    return (
        <div>
            <Helmet>
                <title>CongoPreneur - Voice Of Congo</title>
            </Helmet>
            <CongoPreneurBanner />
        </div>
    )
}

export default CongoPreneur