export const menuList = [
  {
    name: "Actualités",
    href: "/actu",
  },
  {
    name: "Buzz",
    href: "/buzz",
  },
  {
    name: "Culture",
    href: "/culture",
  },
  {
    name: "Politique",
    href: "/politique",
  },
  {
    name: "Sport",
    href: "/sport",
  },
  {
    name: "Evénements",
    href: "/evenements",
  },
  {
    name: "CongoPreneur",
    href: "/congopreneur",
  },
];
