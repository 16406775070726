import { Box, Button, Grid, Typography, alpha, useTheme } from "@mui/material";
import React from "react";


const CongoPreneurBanner = ({ title, subtitle, image }) => {
    const theme = useTheme();
    return (
        <Grid
            container
            sx={{
                overflow: "hidden",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `linear-gradient(
                    to bottom,
                     ${alpha(theme.palette.primary.main, 0.4)},
                     ${alpha(theme.palette.primary.main, 0.5)}
                  ), url('/images/cover.JPG') `,
                p: 6,
                color: 'white'
            }}
            spacing={2}
        >
            <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                item
                xs={12}
                spacing={2}
                md={6}
            >
                <Box sx={{ p: 5 }}>
                    <Typography textAlign={"left"} fontWeight={'700'} variant="h2">
                        La Voix des Entrepreneurs Congolais
                    </Typography>
                    <Typography sx={{ my: 2 }} textAlign={"left"}>
                        CongoPreneur vous offre une plongée au cœur de l’entrepreneuriat au Congo avec des interviews exclusives, des reportages immersifs et des articles sur les acteurs et les tendances qui façonnent l’avenir économique du pays.
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export default CongoPreneurBanner;
