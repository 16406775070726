import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { footerData, informations, services } from "../../data";
import { menuList } from "../header/menuList";
import HomeIcon from '@mui/icons-material/Home';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';

const Footer = () => {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: theme.palette.primary.main, py: 3 }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography sx={{ display: { xs: "none", md: "flex" } }}>
              <img src={"/images/logo-mono.png"} alt="logo" width={"70%"} />
            </Typography>
            <Typography
              textAlign={"center"}
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <img src={"/images/logo-mono.png"} alt="logo" width="30%" />
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              sx={{ color: 'white' }}
              variant="h6"
            >
              Rubriques
            </Typography>
            {informations.map((item) => (
              <Button
                component="a"
                href={item.href}
                sx={{
                  color: 'white',
                  fontSize: 17,
                  display: "block",
                  p: 0,
                  "&:hover": {
                    color: theme.palette.secondary.main,
                  },
                }}
              >
                {item.title}
              </Button>
            ))}
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              sx={{ color: 'white' }}
              variant="h6"
            >
              Services
            </Typography>
            {services.map((item) => (
              <Button
                component="a"
                href={item.href}
                sx={{
                  color: 'white',
                  fontSize: 17,
                  display: "block",
                  p: 0,
                  "&:hover": {
                    color: theme.palette.secondary.main,
                  },
                }}
              >
                {item.name}
              </Button>
            ))}
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              sx={{ color: 'white' }}
              variant="h6"
            >
              Contactez-nous
            </Typography>
            <Button startIcon={<HomeIcon />} sx={{ color: 'white', textAlign: 'left' }}>
              11, Avenue du plan <br />
              Q/Ndolo <br />
              Kinshasa - Barumbu
            </Button><br />
            <Button startIcon={<MailIcon />} sx={{ color: 'white' }}>contact@voiceofcongo.net</Button><br />
            <Button startIcon={<CallIcon />} sx={{ color: 'white' }}>+243 827 215 355</Button>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ backgroundColor: theme.palette.secondary.main, my: 2 }} />
            <Typography
              textAlign="center"
              color={'white'}
              sx={{
                fontSize: 13,
                my: 2,
              }}
            >
              <Box sx={{ my: 2 }}>
                {footerData.communnity.socials.map((social) => (
                  <IconButton
                    component="a"
                    href={social.link}
                    sx={{
                      "&:hover": {
                        color: theme.palette.secondary.main,
                        fontSize: 25,
                      },
                    }}
                  >
                    {social.icon}
                  </IconButton>
                ))}
              </Box>
              A propos de nous | Mentions légales | Politiques de confidentialité <br />
              Copyright &copy; {new Date().getFullYear()}, <b>VOICE OF CONGO</b>. Tous droits
              réservés.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
