import React from 'react'
import { Box, CardMedia, Container, Grid, Typography, alpha, useTheme } from '@mui/material'
import ALaUneActu from '../../components/actu/ALaUne'
import ActuSection from '../../components/actu/Section'
import Helmet from 'react-helmet'

const Actu = () => {
    const theme = useTheme()
    return (
        <div>
            <Helmet>
                <title>Actualités - Voice Of Congo</title>
            </Helmet>
            <Container >
                <Box sx={{ p: 5 }}>
                    <Grid
                        sx={{
                            //backgroundColor: theme.palette.primary.main,
                            p: 5, height: 300,
                            mt: 2,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundImage: `linear-gradient(
                                to bottom,
                                 ${alpha(theme.palette.primary.main, 0.9)},
                                 ${alpha(theme.palette.primary.main, 0.8)}
                              ), url('/images/cover.JPG') `,
                        }}
                        container
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Box sx={{ width: { xs: '80%', md: '40%' } }}>
                                    <img style={{ textAlign: 'center' }} src='/images/slogan.png' width={'100%'} alt='underline' />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <ALaUneActu />
            <ActuSection />
        </div>
    )
}

export default Actu