import { Box, CardMedia, Container, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import {
    collection,
    query,
    where,
    orderBy,
    onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import parse from 'html-react-parser'

const ALaUneActu = () => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const colRef = collection(db, "articles");
        const q = query(
            colRef,
            where("rubrique", "==", "Actualité"),
            orderBy("createdAt", "desc")
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
            let content = [];
            if (snapshot.docs) {
                snapshot.docs.forEach((doc) =>
                    content.push({ id: doc.id, ...doc.data() })
                );
                setArticles(content);
            }
        });

        // Unsubscribe from events when no longer in use
        return () => unsubscribe();
    }, []);

    return (
        <Container>
            <Grid container spacing={2} sx={{ p: 5 }}>
                <Grid item xs={12}>
                    <Typography variant='h3' color={'primary'}>ACTUALITÉS A LA UNE</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    {articles && articles.slice(0, 1).map(article => (
                        <Box>
                            <Box>
                                {article.video ? <ReactPlayer
                                    url={article.video}
                                    height="400px"
                                    width={"100%"}
                                    controls={true}
                                /> : (
                                    <CardMedia
                                        component="img"
                                        height="400"
                                        image={article.image}
                                        alt="image"
                                        sx={{ backgroundColor: '#eee' }}
                                    />
                                )}

                            </Box>
                            <Typography sx={{ my: 2 }} fontWeight={'700'}>{article.rubrique}</Typography>
                            <Typography sx={{ display: 'block', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }} variant='h6' component={'a'} href={article.rubrique + '/' + article.id} variant='h5'>Lorem ipsum</Typography>
                            <Typography sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }} component={'a'} href={article.rubrique + '/' + article.id}>
                                {parse(article.content.slice(0, 200))}
                            </Typography>
                        </Box>
                    ))}
                </Grid>
                <Grid container spacing={2} item xs={12} md={6}>
                    {articles && articles.slice(0, 4).map((article) => (
                        <Grid item xs={6}>
                            <Box>
                                <CardMedia
                                    component="img"
                                    height="150"
                                    image={article.image}
                                    alt="image"
                                    sx={{ backgroundColor: '#eee' }}
                                />
                            </Box>
                            <Typography sx={{ my: 1 }} fontWeight={'700'} fontSize={12}>{(article.rubrique).toUpperCase()}</Typography>
                            <Typography sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }} component={'a'} href={article.rubrique + '/' + article.id} variant='h6' fontWeight={'700'}>
                                {article.title}
                            </Typography>
                            <Typography sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }} component={'a'} href={article.rubrique + '/' + article.id}> {parse(article.content.slice(0, 50))}</Typography>
                        </Grid>
                    ))}

                </Grid>
                <Grid container spacing={2} item xs={12}>
                    {articles && articles.slice(4, 8).map((article) => (
                        <Grid item xs={12} md={3}>
                            <Box>
                                <CardMedia
                                    component="img"
                                    height="150"
                                    image={article.image}
                                    alt="image"
                                    sx={{ backgroundColor: '#eee' }}
                                />
                            </Box>
                            <Typography sx={{ my: 1 }} fontWeight={'700'} fontSize={12}>{(article.rubrique).toUpperCase()}</Typography>
                            <Typography sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }} component={'a'} href={article.rubrique + '/' + article.id} variant='h6' fontWeight={'700'}>
                                {article.title}
                            </Typography>
                            <Typography sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }} component={'a'} href={article.rubrique + '/' + article.id}> {parse(article.content.slice(0, 100))}</Typography>
                        </Grid>
                    ))}

                </Grid>
            </Grid>
        </Container>
    )
}

export default ALaUneActu