import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import NavItem from "./NavItem";
import { menuList } from "../menuList";
import { Drawer, IconButton, Typography, alpha, useTheme } from "@mui/material";
import { footerData } from "../../../data";

const SidebarNav = ({ onClose, open, variant }) => {
  const theme = useTheme();

  return (
    <Drawer
      anchor="left"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: 280,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: `linear-gradient(
                                to bottom,
                                 ${alpha(theme.palette.primary.main, 0.9)},
                                 ${alpha(theme.palette.primary.main, 0.8)}
                              ), url('/images/cover.JPG') `,
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          padding: 1,
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
            width={1}
            paddingX={2}
            paddingY={1}
          >
            <Typography
              textAlign={"center"}
              component="a"
              sx={{ mr: 0.5 }}
              href={"/"}
            >
              <img src="/images/logo.png" alt="logo" width={150} />
            </Typography>
          </Box>
          <Box paddingX={2} paddingY={2}>
            {menuList.map((page) =>
              page.items ? (
                <Box sx={{ ml: 1, fontSize: 15 }}>
                  <NavItem title={page.name} items={page.items} />
                </Box>
              ) : (
                <Button
                  component="a"
                  href={page.href}
                  sx={{
                    display: "block",
                    color: 'white',
                    fontSize: 17,
                    "&:hover": {
                      color: theme.palette.secondary.main,
                    },
                  }}
                >
                  {page.name}
                </Button>
              )
            )}
          </Box>
          <Box sx={{ my: 2 }}>
            {footerData.communnity.socials.map((social) => (
              <IconButton
                component="a"
                href={social.link}
                sx={{
                  "&:hover": {
                    color: theme.palette.secondary.main,
                    fontSize: 25,
                  },
                }}
              >
                {social.icon}
              </IconButton>
            ))}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
