import React from 'react'
import EventBanner from '../../components/events/Banner'
import { Helmet } from 'react-helmet'

const Event = () => {
    return (
        <div>
            <Helmet>
                <title>Evénements - Voice Of Congo</title>
            </Helmet>
            <EventBanner />
        </div>
    )
}

export default Event