import { Box, CardMedia, Container, Grid, Typography, alpha, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import {
    collection,
    query,
    where,
    orderBy,
    onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import parse from 'html-react-parser'
import ALaUne from '../../components/home/News';
import HomeActu from '../../components/home/Actu';
import HomeBuzz from '../../components/home/Buzz';
import HomeCulture from '../../components/home/Culture';
import HomeSport from '../../components/home/Sport';

const Home = () => {
    const theme = useTheme()
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const colRef = collection(db, "articles");
        const q = query(
            colRef,
            where("rubrique", "==", "Politique"),
            orderBy("createdAt", "desc")
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
            let content = [];
            if (snapshot.docs) {
                snapshot.docs.forEach((doc) =>
                    content.push({ id: doc.id, ...doc.data() })
                );
                setArticles(content);
            }
        });

        // Unsubscribe from events when no longer in use
        return () => unsubscribe();
    }, []);

    return (
        <div>
            <Container >
                <Box sx={{ p: 5 }}>
                    <Grid
                        sx={{
                            //backgroundColor: theme.palette.primary.main,
                            p: 5, height: 300,
                            mt: 2,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundImage: `linear-gradient(
                                to bottom,
                                 ${alpha(theme.palette.primary.main, 0.9)},
                                 ${alpha(theme.palette.primary.main, 0.8)}
                              ), url('/images/cover.JPG') `,
                        }}
                        container
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Box sx={{ width: { xs: '80%', md: '40%' } }}>
                                    <img style={{ textAlign: 'center' }} src='/images/slogan.png' width={'100%'} alt='underline' />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <ALaUne />

            <HomeActu />

            <HomeBuzz />

            <HomeCulture />

            <HomeSport />
        </div>
    )
}

export default Home