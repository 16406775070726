import Actu from "./pages/actu"
import ActuDetails from "./pages/actu/Details"
import Buzz from "./pages/buzz"
import BuzzDetails from "./pages/buzz/Details"
import CongoPreneur from "./pages/congopreneur"
import Culture from "./pages/culture"
import CultureDetails from "./pages/culture/Details"
import Event from "./pages/events"
import Home from "./pages/home"
import Politic from "./pages/politic"
import PoliticDetails from "./pages/politic/Details"
import Sport from "./pages/sport"
import SportDetails from "./pages/sport/Details"

const routes = [
  {
    name: "Home",
    path: "/",
    component: <Home />,
  },
  {
    name: "Actualités",
    path: "/actu",
    component: <Actu />,
  },
  {
    name: "Actualités",
    path: "/Actualité/:articleID",
    component: <ActuDetails />,
  },
  {
    name: "Buzz",
    path: "/buzz",
    component: <Buzz />,
  },
  {
    name: "Buzz details",
    path: "/Buzz/:articleID",
    component: <BuzzDetails />,
  },
  {
    name: "Culture",
    path: "/culture",
    component: <Culture />,
  },
  {
    name: "Culture details",
    path: "/Culture/:articleID",
    component: <CultureDetails />,
  },
  {
    name: "Sport",
    path: "/sport",
    component: <Sport />,
  },
  {
    name: "Sport details",
    path: "/Sport/:articleID",
    component: <SportDetails />,
  },
  {
    name: "Politic",
    path: "/politique",
    component: <Politic />,
  },
  {
    name: "Politic details",
    path: "/Politique/:articleID",
    component: <PoliticDetails />,
  },
  {
    name: "CongoPreneur",
    path: "/congopreneur",
    component: <CongoPreneur />,
  },
  {
    name: "Evenements",
    path: "/evenements",
    component: <Event />,
  },
]

export default routes