import {
    Box,
    Container,
    Divider,
    Grid,
    Typography,
    alpha,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import parse from "html-react-parser";
import moment from "moment";

const PoliticDetails = () => {
    const params = useParams();
    const [article, setArticle] = useState({});
    const theme = useTheme()

    useEffect(() => {
        const docRef = doc(db, "articles", params.articleID);

        const unsubscribe = onSnapshot(
            docRef,
            (snapshot) => {
                if (snapshot.data()) {
                    setArticle({ ...snapshot.data(), id: snapshot.id });
                }
            },
            (err) => {
                console.log(err.message);
            }
        );
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <Box>
            <Container sx={{ py: 8 }}>
                <Grid container sx={{ p: 3 }} spacing={2}>
                    <Grid sx={{
                        px: 3,
                        p: 5, height: 400,
                        mt: 2,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `linear-gradient(
                    to bottom,
                     ${alpha(theme.palette.primary.main, 0.0)},
                     ${alpha(theme.palette.primary.main, 0.0)}
                  ), url(${article && article.image}) `,
                    }} item xs={12}>

                    </Grid>
                    <Grid sx={{ mt: 3 }} item xs={12}>
                        <Typography variant="h6">{article && article.title}</Typography>
                        <Typography>{moment(article.createdAt).fromNow()}</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 3 }} />
                <Grid container spacing={2}>
                    <Grid item sx={12}>
                        <Typography textAlign={"justify"}>
                            {article.content && parse(article.content)}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default PoliticDetails;
