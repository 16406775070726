import React, { useEffect, useState } from 'react'
import { Box, CardMedia, Container, Grid, Typography, useTheme } from '@mui/material'
import ReactPlayer from 'react-player'
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { db } from '../../firebase'
import parse from 'html-react-parser'

const CultureSection = () => {
    const theme = useTheme();
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const colRef = collection(db, "articles");
        const q = query(
            colRef,
            where("rubrique", "==", 'Culture'),
            orderBy("createdAt", "desc")
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            let content = [];
            if (snapshot.docs) {
                snapshot.docs.forEach((doc) =>
                    content.push({ id: doc.id, ...doc.data() })
                );
                setArticles(content);
            }
        });
        // Unsubscribe from events when no longer in use
        return () => unsubscribe();
    }, []);

    return (
        <Container>
            <Grid container spacing={2} sx={{ p: 5 }}>
                <Grid item xs={12}>
                    <Typography variant='h3' color={'primary'}>PLUS DE CULTURE</Typography>
                </Grid>
                <Grid container spacing={2} item xs={12}>
                    {articles && articles.map((article) => (
                        <Grid item md={3} xs={12}>
                            <Box>
                                <CardMedia
                                    component="img"
                                    height="150"
                                    image={article.image}
                                    alt="image"
                                    sx={{ backgroundColor: '#eee' }}
                                />
                            </Box>
                            <Typography sx={{ my: 1 }} fontWeight={'700'} fontSize={12}>{(article.rubrique).toUpperCase()}</Typography>
                            <Typography sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }} component={'a'} href={article.rubrique + '/' + article.id} variant='h6' fontWeight={'700'}>
                                {article.title}
                            </Typography>
                            <Typography sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }} component={'a'} href={article.rubrique + '/' + article.id}> {parse(article.content.slice(0, 50))}</Typography>
                        </Grid>
                    ))}

                </Grid>
            </Grid>
        </Container>
    )
}

export default CultureSection